const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://comfortcars.dvgeo.app' : 'http://192.168.100.106:3063',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://comfortcars.dvgeo.app' : 'http://192.168.100.106:3063',
    api: 'api/',
    apiSocket: 'comfortcars::1.2',
    nameDir: 'comfortcars',
    package: ' app.dvgeo.comfortcars.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDxbkNbSAV-Xyhz8khgaZQ6UL4hJEFsgSM',
    appName: 'Comfort Cars',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#204B6E',
    colorDark: '#55A9C2',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.comfortcars.passenger',
    playStoreDriverId: 'app.dvgeo.comfortcars.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "sergiorchavez06@gmail.com",
};
export default config;
